<template>
    <v-container
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageAll.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                          Виртуальный наставник (запуск новичка)
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                      <p class="mb-5">
                        StarMentor - теоретический курс по основе МЛМ бизнеса и партнерства вместе с компанией LR.
                      </p>
                      <p class="mb-5">
                        Цель - запуск новичка. Объяснить новичку где он, что ему предстоит делать, какие для этого есть инструменты. Написать первый список.
                        Понять основные правила компании и сетевого бизнеса.
                        Получить уверенность в компании и продукте. ПРИНЯТИЕ ИСТИННОГО, ВНУТРЕННЕГО РЕШЕНИЯ, развивать бизнес вместе с компанией.
                      </p>
                      <p class="mt-10">
                          <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                        <a href="https://salebot.pro/cs/StarMentor" target="_blank">Курс "Виртуальный наставник"</a>
                      </p>
                      <p class="mb-n2">
                          <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                          <strong>Ссылка на курс "Виртуальный наставник"</strong>:
                          <v-text-field
                              :value="`https://salebot.pro/cs/StarMentor`"
                              readonly
                              id="referLinkCourse"
                          ></v-text-field>
                      </p>
                      <v-btn
                          color="gray"
                          @click="copyRefLinkCourse"
                          class="mb-5"
                      >
                          Скопировать ссылку
                      </v-btn>
                      <p class="mb-5">
                        <a href="https://youtu.be/zf4uoWmkeuA" target="_blank">ВИДЕО "КАК ПРОЙТИ РЕГИСТРАЦИЮ НА КУРС Виртуальный наставник"</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starmentor/7A65zwp-e9P" target="_blank">Инструкция для наставников по StarMentor</a>
                        (как использовать инструмент для ваших новичков)
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starmentor/UU7rXoKprFZ" target="_blank">Правила прохождения Виртуального наставника</a>
                      </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageAll.paytime || packageAll.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "VirtualLearn",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAll: 'user/getProfileAll'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        copyRefLinkCourse () {
            let referLinkCourse = document.getElementById('referLinkCourse')

            referLinkCourse.select()

            document.execCommand('copy')
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAll()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.p-title
  text-transform: uppercase
  font-weight: bold

.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
